var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.tableData},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.isEditing)?_c('v-select',{staticClass:"d-input-no-foot",staticStyle:{"width":"100px"},attrs:{"items":_vm.salaryTypeLst,"placeholder":"Chọn loại","dense":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}}):_c('v-chip',{attrs:{"color":_vm._f("salaryTypeColor")(item.type),"text-color":"white"}},[_vm._v(_vm._s(_vm._f("salaryTypeText")(item.type)))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.isEditing)?_c('v-text-field',{staticClass:"d-input-no-foot",staticStyle:{"width":"100px"},attrs:{"type":"number","dense":"","placeholder":"Giá trị"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('v-chip',{attrs:{"label":"","color":"#004D40","outlined":""}},[_vm._v(_vm._s(_vm._f("numberF0")(item.value)))])]}},{key:"item.work_day_const",fn:function(ref){
var item = ref.item;
return [(item.isEditing)?_c('v-text-field',{staticClass:"d-input-no-foot",staticStyle:{"width":"100px"},attrs:{"type":"number","dense":"","placeholder":"Giá trị"},model:{value:(item.work_day_const),callback:function ($$v) {_vm.$set(item, "work_day_const", $$v)},expression:"item.work_day_const"}}):_c('v-chip',{attrs:{"label":"","color":"#004D40","outlined":""}},[_vm._v(_vm._s(item.work_day_const))])]}},{key:"item.w_links",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"dense":"","color":"#033"}},[_c('router-link',{attrs:{"to":'/admin/users/'+item.id}},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"blue darken-1","text-color":"white"}},[_c('i',{staticClass:"fas fa-ellipsis-h",staticStyle:{"color":"white"}})])],1)],1)]}},{key:"item.w_ac",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"dense":"","color":"#033"}},[(item.isEditing)?[_c('v-btn',{attrs:{"loading":item.isLoading,"depressed":"","small":"","color":"green","text-color":"white"},on:{"click":function($event){return _vm.saveChange(item)}}},[_c('i',{staticClass:"fas fa-check",staticStyle:{"color":"white"}})]),_c('v-btn',{attrs:{"loading":item.isLoading,"depressed":"","small":"","color":"orange","text-color":"white"},on:{"click":function($event){return _vm.undoOVal(item)}}},[_c('i',{staticClass:"fas fa-undo-alt",staticStyle:{"color":"white"}})])]:[_c('v-btn',{attrs:{"loading":item.isLoading,"depressed":"","small":"","color":"blue","text-color":"white"},on:{"click":function($event){item.isEditing=true}}},[_c('i',{staticClass:"far fa-edit",staticStyle:{"color":"white"}})])]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }