<template>
  <div>
    <v-data-table :loading="isLoading" :headers="headers" :items="tableData" class="elevation-1">
      <template v-slot:item.type="{ item }">
        <v-select
          style="    width: 100px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          :items="salaryTypeLst"
          v-model="item.type"
          placeholder="Chọn loại"
          dense
        ></v-select>
        <v-chip
          v-else
          :color="item.type|salaryTypeColor"
          text-color="white"
        >{{ item.type|salaryTypeText }}</v-chip>
      </template>
      <template v-slot:item.value="{ item }">
        <v-text-field
        v-model="item.value"
          type="number"
          style="    width: 100px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          dense
          placeholder="Giá trị"
        ></v-text-field>
        <v-chip v-else label color="#004D40" outlined>{{ item.value|numberF0 }}</v-chip>
      </template>
      <template v-slot:item.work_day_const="{ item }">
        <v-text-field
        v-model="item.work_day_const"
          type="number"
          style="    width: 100px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          dense
          placeholder="Giá trị"
        ></v-text-field>
        <v-chip v-else label color="#004D40" outlined>{{ item.work_day_const }}</v-chip>
      </template>
      <template v-slot:item.w_links="{ item }">
        <v-btn-toggle dense color="#033">
          <router-link :to="'/admin/users/'+item.id">
            <v-btn depressed small color="blue darken-1" text-color="white">
              <i class="fas fa-ellipsis-h" style="color:white"></i>
            </v-btn>
          </router-link>
        </v-btn-toggle>
      </template>
      <template v-slot:item.w_ac="{ item }">
        <v-btn-toggle dense color="#033">
          <template v-if="item.isEditing">
            <v-btn :loading="item.isLoading" depressed small color="green" @click="saveChange(item)" text-color="white">
              <i class="fas fa-check" style="color:white"></i>
            </v-btn>
            <v-btn
              :loading="item.isLoading"
              @click="undoOVal(item)"
              depressed
              small
              color="orange"
              text-color="white"
            >
              <i class="fas fa-undo-alt" style="color:white"></i>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              :loading="item.isLoading"
              @click="item.isEditing=true"
              depressed
              small
              color="blue"
              text-color="white"
            >
              <i class="far fa-edit" style="color:white"></i>
            </v-btn>
          </template>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { getSalarySetupTable,updateUserSalarySetup } from "@/api/salary";
import { SalaryTypeMap } from "@/utils/mapping";
export default {
  data() {
    return {
      headers: [
        { text: "Mã", value: "user_login" },
        { text: "Tên", value: "user_fullname" },
        { text: "Loại lương", value: "type" },
        { text: "Giá trị", value: "value" },
        { text: "Đủ ngày công", value: "work_day_const" },
        { text: "Actions", value: "w_ac", align: "center" },
        { text: "Links", value: "w_links", align: "center" }
      ],
      tableData: [],
      isLoading: false
    };
  },
  computed: {
    salaryTypeLst() {
      let arr = [];
      for (let i in SalaryTypeMap) {
        arr.push({
          text: SalaryTypeMap[i].text,
          value: i
        });
      }
      return arr;
    }
  },
  methods: {
      saveChange(item){
          item.isLoading = true;
          updateUserSalarySetup({
              id:item.id,
              type:item.type,
              value:item.value,
              work_day_const:item.work_day_const
          }).then(()=>{
            this.createOVal(item);
          }).catch(()=>{
              item.isLoading = false;
          })
          
      },
    createOVal(e) {
      e.oValue = e.value;
      e.oWork_day_const = e.work_day_const;
      e.oType = e.type;
      e.isEditing = false;
      e.isLoading = false;
    },
    undoOVal(e) {
      e.value = e.oValue;
      e.type = e.oType;
      e.work_day_const = e.oWork_day_const;
      e.isEditing = false;
      e.isLoading = false;
    },
    fetchData() {
      this.isLoading = true;
      getSalarySetupTable().then(res => {
        res.data.forEach(e => {
          this.createOVal(e);
        });
        this.tableData = res.data;
        this.isLoading = false;
      });
    }
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lương", route: "/salary/setup" },
      { title: "Cài đặt lương", route: "/salary/setup" }
    ]);
    this.fetchData();
  }
};
</script>